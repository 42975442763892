<template>
  <div class="payment-page bg-base-200">
    <div class="payment-qpay">
      <div class="payment-options">
        <div class="p-header">
          <h2>Qpay эрх сунгах</h2>
        </div>
        <!--        <strong v-if="$user != null">Таны ID: {{ $user.id }}</strong>-->

        <div class="stepper">
          <ul class="step-1">
            <li>
              <Button
                  :class="`p-button-rounded p-button-secondary p-button-outlined p-button-sm ${step >= 1 ? 'active' : ''}`">
                <i v-if="step == 1" class="pi pi-check"></i>
                <span v-if="step != 1">1</span>
              </Button>
              <span>Эрх сонгох</span>
            </li>
            <li>
              <Button
                  :class="`p-button-rounded p-button-secondary p-button-outlined p-button-sm ${step >= 2 ? 'active' : ''}`">
                <i v-if="step == 2" class="pi pi-check"></i>
                <span v-else>2</span>
              </Button>
              <span>Төлбөр төлөх</span>
            </li>
            <li>
              <Button icon="pi pi-times"
                      :class="`p-button-rounded p-button-secondary p-button-outlined p-button-sm ${step == 3 ? 'active' : ''}`">
                <i v-if="step == 3" class="pi pi-check"></i>
                <span v-else>3</span>
              </Button>
              <span>Сүүлийн алхам</span>
            </li>
          </ul>
        </div>

        <div v-if="step == 1" class="choices">
          <ProgressSpinner v-if="isLoading" style="width:50px;height:50px" strokeWidth="3" animationDuration="1s"/>
          <div v-else>
            <a href="javascript:void(0)" v-for="item in paymentOptions" :key="item.id"
               :class="selectedCard == item.id ? 'active' : ''" @click="activeCard(item.id)">
              <h4>{{ item.title }} </h4>
              <span class="price">{{ item.price }}₮</span>
              <span class="check" v-if="selectedCard == item.id">
              <i class="pi pi-check"></i>
            </span>
            </a>
          </div>
          <button
              :class="`primary-btn btn-process ${isDisabled ? 'disabled' : ''} ${isProceeding ? 'loading' : ''}`"
              :disabled="isDisabled"
              @click="createQpayInvoice">{{ btnTxt }}
          </button>
        </div>

        <div v-if="step == 2" class="payment-options qpay-render">
          <img class="qpay-img" v-if="qpayInvoice != null" :src="`data:image/png;base64,${qpayInvoice.qr_image}`"/>
          <div class="deeplink" v-if="qpayInvoice != null">
            <h3>Та утсан дээрээ суулгасан банкны аппликейшнээ сонгож төлбөрөө хийнэ үү</h3>
            <ul>
              <li>
                <a :data-app="`khanbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.slide.khaanbank" data-store-ios="610317572"
                   :href="`khanbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/1.png" alt="khanbank">
                  <span>Khan Bank</span>
                </a>
              </li>
              <li>
                <a :data-app="`tdbbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.tdb.pay" data-store-ios="1458831706"
                   :href="`tdbbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/2.png" alt="tdbbank">
                  <span>TDB Online</span>
                </a>
              </li>
              <li>
                <a :data-app="`statebank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.statebank.mobilebank" data-store-ios="703343972"
                   :href="`statebank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/3.png" alt="statebank">
                  <span>State Bank</span>
                </a>
              </li>
            </ul>

            <ul>
              <li>
                <a :data-app="`xacbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.xacbank.smartbank" data-store-ios="781439021"
                   :href="`xacbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/4.png" alt="xacbank">
                  <span>XacBank</span>
                </a>
              </li>
              <li>
                <a :data-app="`capitronbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.ecapitron" data-store-ios="1312706504"
                   :href="`capitronbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/5.png" alt="capitronbank">
                  <span>Capitron Bank</span>
                </a>
              </li>
              <li>
                <a :data-app="`ckbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.ckbank.smartbank2" data-store-ios="1180620714"
                   :href="`ckbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/6.png" alt="ckbank">
                  <span>Chinggis khaan</span>
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a :data-app="`bogdbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="com.bogdbank.ebank.v2" data-store-ios="1475442374"
                   :href="`bogdbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/7.png" alt="bogdbank">
                  <span>Bogd Bank</span>
                </a>
              </li>
              <li>
                <a :data-app="`nibank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.nibank.mobilebank" data-store-ios="882075781"
                   :href="`nibank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/8.jpeg" alt="nibank">
                  <span>NIBank</span>
                </a>
              </li>
              <li>
                <a :data-app="`most://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                   data-store-android="mn.grapecity.mostmoney" data-store-ios="487144325"
                   :href="`most://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                  <img src="/assets/images/banks/9.png" alt="most">
                  <span>MostMoney</span>
                </a>
              </li>
            </ul>
          </div>

          <p class="warning-txt">
            Төлбөрөө төлсөн бол "ТӨЛБӨР ШАЛГАХ" товч дээр дарна уу.
            <small style="display: block;">Жич: Төлсөн ч төлөөгүй гэсэн хариу өгч байвал дахин шалгаарай.</small>
          </p>

          <div class="qpay-actions">
            <Button
                size="small"
                :class="`p-button-outlined`"
                :loading="isLoadingCheck"
                label="Төлбөр шалгах"
                @click="checkPayment"/>

            <Button
                size="small"
                :class="`p-button-outlined`"
                label="Цуцлах"
                @click="cancelQpay"/>
          </div>
        </div>

        <div v-if="step == 3" class="result-step">
          <div class="qpay-result">
            <div v-if="isSuccess" class="success">
              <div class="icon-wrap">
                <i class="pi pi-check-circle"></i>
              </div>
              <p>{{ resultMsg }}</p>
            </div>

            <div v-else class="error">
              <div class="icon-wrap">
                <i class="pi pi-info-circle"></i>
              </div>
              <p>{{ resultMsg }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../store/_boot/baseApi";
import {mapGetters} from "vuex";

export default {
  name: "qpay",
  components: {},

  data() {
    return {
      isLoading: false,
      isLoadingCheck: false,
      selectedCard: null,
      paymentType: null,
      isDisabled: true,
      qpayInvoice: null,
      isProceeding: false,
      isLoadingQpay: false,
      btnTxt: "Үргэлжлүүлэх",
      accessToken: null,
      bill: null,
      countTimer: null,
      isSuccess: false,
      resultMsg: "",
      step: 1,
    }
  },

  computed: {
    ...mapGetters(['user', 'paymentOptions'])
  },

  created() {
    this.$store.dispatch('getPaymentOptions', {vm: this});
  },

  methods: {
    resetQpay() {
      clearInterval(this.countTimer);
      this.paymentType = null;
      this.selectedCard = null;
      this.isDisabled = true;
      this.step = 1;
      this.btnTxt = "Үргэлжлүүлэх";
      this.isProceeding = false;
    },

    activeCard(item) {
      if (this.selectedCard == item) {
        this.selectedCard = null;
        this.isDisabled = true;
      } else {
        this.selectedCard = item;
        this.isDisabled = false;
      }
    },

    showPaymentModal(type) {
      if (this.user == null) {
        this.$Message.error("Та нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой!");
        return;
      }
      this.paymentType = type;
      this.$modal.show("payment-modal");
    },


    createQpayInvoice() {
      this.isProceeding = true;
      this.btnTxt = "Түр хүлээнэ үү";

      api.get(`/api/qpay/invoice/${this.user.id}/${this.selectedCard}`).then(({data}) => {
        if (data.status) {
          this.qpayInvoice = data.data;

          setTimeout(() => {
            this.step++;
            this.isProceeding = false;
            this.btnTxt = "Үргэлжлүүлэх";
            this.bill = data.bill;

            // this.countTimer = setInterval(() => {
            //   this.count--;
            //   if (this.count === 0) {
            //     this.cancelQpay();
            //     this.$notify.error({
            //       title: 'Хугацаа дууслаа',
            //       message: 'Та төлбөр төлөх хүсэлтээ дахин илгээнэ үү'
            //     });
            //   }
            // }, 1000);
            // this.checkPayment(data.bill);
            
          }, 1000);
        } else {
          setTimeout(() => {
            this.isProceeding = false;
            this.$notify.error({
              title: 'Алдаа гарлаа',
              message: data.msg,
            });
          }, 1000);
        }
      }).catch(e => {
        console.log(e);
        setTimeout(() => {
          this.isProceeding = false;
          this.$Message.error("Алдаа гарлаа");
          this.$notify.error({
            title: 'Алдаа гарлаа',
            message: "",
          });
        }, 1000);
      })
    },

    checkPayment() {
      this.isLoadingCheck = true;
      api.get(`/api/qpay/check/${this.bill}`).then(({data}) => {
        if (data.status == 1) {
          this.gotoFinal();
          this.isSuccess = true;
          this.resultMsg = data.msg;
        }

        if (data.status == 0) {
          this.gotoFinal();
          this.isSuccess = false;
          this.resultMsg = data.msg;
        }

        if (data.status == 2 && this.step == 2) {
          this.$toast.warning("Төлбөр төлөгдөөгүй байна!")
        }

        this.isLoadingCheck = false;
      }).catch(e => {
        console.log(e);
        this.isLoadingCheck = false;
        this.cancelQpay();
        // this.$notify.error({
        //   title: 'Алдаа гарлаа',
        //   message: data.msg
        // });
      })
    },

    gotoFinal() {
      this.step = 3;
      clearInterval(this.countTimer);
      this.paymentType = null;
      this.selectedCard = null;
      this.isDisabled = true;
      this.isProceeding = false;
      this.count = 90;
    },

    cancelQpay() {
      this.step = 1;
      this.resetQpay();
    }
  }
}
</script>
